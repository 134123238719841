import { Analytics } from '@cbreone/core-services';
import TextField from '@marketiq/marketiq-core-react/components/DebouncedTextField';
import { parseInputChange } from '@cbreone/utilities';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';
import setSurveySummaryAction from '../../redux/actions/surveyAction';
import { SurveySummary } from '../../redux/types/dataTypes';
import * as mediaService from '../../services/media-service';
import { Survey } from '../../types';
import { CoverBackgroundImage } from '../../types/cover-background-image';
import { compareObjects } from '../../utilities';
import SurveyContactList from '../SurveyContactList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
  }),
);
const CoverSectionForm: React.FC<Props> = ({
  survey,
  onSurveyUpdate,
  onSurveyContactAdd,
  onSurveyContactDelete,
  onSurveyContactUpdate,
  onSurveyContactOrderChange,
  stateSurvey,
}) => {
  const classes = useStyles();
  const [coverBackgroundImages, setCoverBackgroundImages] = useState<
    CoverBackgroundImage[]
  >([]);
  const [loadData, setLoadData] = useState<boolean>(true);
  const [localData, setLocalData] = useState<SurveySummary>({} as SurveySummary);
  const dispatch: Dispatch<any> = useDispatch();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const data = {
      isDisplayClientName: survey.isDisplayClientName,
      coverTitle: survey.coverTitle,
      coverSubTitle: survey.coverSubTitle,
      coverDate: survey.coverDate,
      clientName: survey.clientName,
      pdfPaperSize: survey.pdfPaperSize,
      isDisplayClientLogo: survey.isDisplayClientLogo,
      id: survey.id,
      coverBackgroundImage: survey.coverBackgroundImage,
    };
    dispatch(setSurveySummaryAction(data));
    setLocalData(data);
  }, []);

  const getCoverBackgroundImages = () =>
    new Promise((resolve, reject) => {
      mediaService.getSurveyCoverImage('SURVEYCOVERIMAGE').then((response) => {
        const coverImages = response.media.map(
          (media: any) =>
          ({
            mediaId: media.mediaId,
            s3Url: media.s3Url,
            cloudfrontUrl: media.cloudfrontUrl,
            displayName: media.displayName,
          } as CoverBackgroundImage),
        );
        resolve(
          coverImages.sort((a: any, b: any) =>
            compareObjects(a, b, 'displayName'),
          ),
        );
      });
    });

  const coverImages = {
    Industrial: 'Warehouse Interior 3',
    Office: 'Office Exterior',
    'Mixed Use': 'Office Exterior',
    OPRE: 'Office Exterior',
    Retail: 'Interior Shopping Mall',
    Multifamily: 'Business Meeting',
  };

  const autoUpdateCoverImage = (data:any) => {
    const { propertyType } = survey;
    const propTypeCheck:any = propertyType;
    const coverImageKeys = Object.keys(coverImages);
    if (propertyType?.trim() !== '' && coverImageKeys?.includes(propTypeCheck)) {
      const imgName = Object.entries(coverImages).filter((data: any, index: number) =>
        (coverImageKeys[index] === propertyType));
      const selectedImg:any = data?.find(
        (media: CoverBackgroundImage) => media.displayName === imgName[0][1],
      );
      const newValue: any = { name: 'coverBackgroundImage', value: selectedImg };
      if (survey?.coverBackgroundImage?.displayName?.trim() === '') {
        onSurveyUpdate(newValue);
      }
    }
  };

  useEffect(() => {
    if (loadData) {
      getCoverBackgroundImages().then((result: any) => {
        setLoadData(false);
        setCoverBackgroundImages(result);
        autoUpdateCoverImage(result);
      });
    }
  }, [loadData]);

  const updateSelectedCoverImage = (e: any) => {
    const selectedImg = coverBackgroundImages.find(
      (media) => media.mediaId === e.target.value,
    );
    e.target.value = { name: 'coverBackgroundImage', value: selectedImg };
    onSurveyUpdate(e.target.value);
  };

  const onSurveySummaryUpdate = (e: React.ChangeEvent<any>) => {
    const { name, value } = (e.target ? parseInputChange(e.target) : e) as {
      name: string;
      value: string | number | boolean | undefined;
    };
    dispatch(
      setSurveySummaryAction({
        ...localData,
        [name]: value,
      }),
    );
    setLocalData({
      ...localData,
      [name]: value,
    });
  };

  return (
    <section>
      <FormControl
        fullWidth
        className={classes.marginBottom}
        variant="outlined"
      >
        <InputLabel id="cover_background_image-label">
          Background Image
        </InputLabel>
        <Select
          labelId="cover_background_image-label"
          id="SC-SurveyCoverImage"
          value={survey.coverBackgroundImage?.mediaId}
          name="coverBackgroundImage"
          onChange={(e) => {
            Analytics.send({
              SurveyCoverImageChanged: survey.coverBackgroundImage.displayName,
            });
            updateSelectedCoverImage(e);
          }}
          label="Background Image"
        >
          {coverBackgroundImages.map((media) => (
            <MenuItem key={media.mediaId} value={media.mediaId}>
              {media.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        className={classes.marginBottom}
        id="SC-SurveyTitle"
        name="coverTitle"
        onChange={onSurveySummaryUpdate}
        onBlur={(event) => {
          onSurveyUpdate(event);
        }}
        placeholder="Survey Title"
        value={localData.coverTitle}
        label="Survey Title"
        fullWidth
        debounceTime={0}
      />
      <TextField
        className={classes.marginBottom}
        id="SC-SurveySubtitle"
        name="coverSubTitle"
        onChange={onSurveySummaryUpdate}
        onBlur={onSurveyUpdate}
        placeholder="Survey Subtitle"
        value={localData.coverSubTitle}
        label="Survey Subtitle"
        fullWidth
        debounceTime={0}
      />
      <TextField
        className={classes.marginBottom}
        id="SC-SurveyDate"
        name="coverDate"
        onChange={onSurveySummaryUpdate}
        onBlur={onSurveyUpdate}
        placeholder={`January 1, ${currentYear}`}
        value={localData.coverDate}
        label="Date"
        fullWidth
        debounceTime={0}
      />
      {/* @Todo "post-pilot" work to reference for later. */}
      {/*  <InputLabel shrink>Client Logo</InputLabel>
      <Button
        className={classes.marginBottom}
        variant="text"
        children="Upload Button"
      /> */}
      <TextField
        className={classes.marginBottom}
        id="SC-SurveyClientName"
        name="clientName"
        onChange={onSurveySummaryUpdate}
        onBlur={onSurveyUpdate}
        placeholder="Client Name"
        value={localData.clientName}
        label="Client Name"
        fullWidth
        debounceTime={0}
      />
      <FormGroup className={classes.marginBottom}>
        <FormControlLabel
          control={
            <Checkbox
              id="SC-ClientNameShowHide"
              name="isDisplayClientName"
              checked={survey.isDisplayClientName}
              onChange={(e) => {
                onSurveySummaryUpdate(e);
                onSurveyUpdate(e);
              }}
            />
          }
          label="Display Client Name"
        />
      </FormGroup>
      {stateSurvey?.clientLogo &&
        <FormGroup className={classes.marginBottom}>
          <FormControlLabel
            control={
              <Checkbox
                id="SC-ClientLogoShowHide"
                checked={survey.isDisplayClientLogo}
                name="isDisplayClientLogo"
                onChange={(e) => {
                  dispatch(setSurveySummaryAction({ isDisplayClientLogo: e.target.checked }));
                  onSurveySummaryUpdate(e);
                  onSurveyUpdate(e);
                }
                }
              />
            }
            label="Display Client Logo"
          />
        </FormGroup>}
      <SurveyContactList
        surveyContactList={survey.contacts || []}
        addSurveyContact={onSurveyContactAdd}
        deleteSurveyContact={onSurveyContactDelete}
        updateSurveyContact={onSurveyContactUpdate}
        reorderSurveyContact={onSurveyContactOrderChange}
      />
    </section>
  );
};

export type Props = {
  onSurveyUpdate: (
    e: React.ChangeEvent<
      | HTMLInputElement
      | HTMLTextAreaElement
      | {
        name: string | undefined;
        value: unknown;
      }
    >,
    checkedOrChild?: boolean | React.ReactNode
  ) => void;
  survey: Survey;
  onSurveyContactAdd: UseSurveyEditorAPI['addSurveyContact'];
  onSurveyContactDelete: UseSurveyEditorAPI['deleteSurveyContact'];
  onSurveyContactUpdate: UseSurveyEditorAPI['updateSurveyContact'];
  onSurveyContactOrderChange: UseSurveyEditorAPI['reorderSurveyAttribute'];
  stateSurvey: SurveySummary;
};
const mapStateToProps = (state: any) => ({
  stateSurvey: state.survey,
});

CoverSectionForm.displayName = 'CoverSectionForm';
export default React.memo(connect(mapStateToProps)(CoverSectionForm));
