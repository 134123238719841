import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { PDFPaperSize } from '../../data/constants';
import { MediaState } from '../../redux/types/dataTypes';
import { GalleryFile, MediaFile, Property } from '../../types';
import { getFullAddressStr, mediaFileToGalleryFile } from '../../utilities';
import PDFPreview from '../PDFPreview';
import SurveyPDFPage from '../SurveyPDFPage';
import usePreviewStyles from './PreviewStyles';

interface FloorplanPage {
  id?: string;
  title: string;
  subtitle: string;
  order: string,
  floorplanUrl: string; // will be the feature image url
  originalUrl?: string;
}

const PropertySitePlanPreview: React.FC<Props> = ({
  property,
  mediaState,
  pdfPaperSize,
  propertyOrder,
  pageMode,
  isVisible,
  floorPlanIndex,
}) => {
  const classes = usePreviewStyles();
  const mediaList = useSelector((state: any) => state.media, shallowEqual);
  const [propertiesPreview, setPropertiesPreview] = useState<FloorplanPage[]>(
    [],
  );
  const pageHeightClassName = classes.pageContainerLandscapeHeaderHeight;
  const pageWidthClassName =
    pdfPaperSize === PDFPaperSize.Portrait
      ? classes.floorplanPortraitContainer
      : classes.floorplanLandscapeContainer;

  const getFloorplansForProperty = (id: string) => {
    const fileState = mediaList[id] || {};
    const { floorPlans } = fileState;
    // eslint-disable-next-line arrow-body-style
    return (
      floorPlans
        ?.filter(
          (media: MediaFile) => media.isHidden === undefined || media.isHidden === false,
        )
        .map((media: MediaFile) => mediaFileToGalleryFile(media)) || []
    );
  };

  useEffect(() => {
    // eslint-disable-next-line no-await-in-loop
    if (property.id && Object.keys(mediaList).length > 0 && isVisible) {
      const floorplans: GalleryFile[] = getFloorplansForProperty(
        property.id,
      );
      const addressDisplay: string = getFullAddressStr(
        property.address1,
        property.city,
        property.state,
        property.postalCode,
      );

      const floorplanPages: FloorplanPage[] = floorplans.map(
        (floorplan: GalleryFile, i: number) => {
          if (floorplan?.displayText.includes('.pdf')) {
            return {
              id: floorplan.id,
              subtitle: addressDisplay || '',
              title: property.name,
              order: propertyOrder,
              floorplanUrl: floorplan.thumbnailUrl || '',
              originalUrl: floorplan.url,
            };
          }
          return {
            subtitle: addressDisplay || '',
            title: property.name,
            order: propertyOrder,
            floorplanUrl: floorplan.url,
          };
        },
      );
      setPropertiesPreview(floorplanPages);
    } else {
      setPropertiesPreview([]);
    }
  }, [property.id, mediaList, isVisible]);

  const SurveyPDFPageView = (floorplanPage: FloorplanPage, elementkey: string) => {
    const floorplanUrl = `${floorplanPage.floorplanUrl}?d=print`;
    return (
      <SurveyPDFPage
        mode={pageMode}
        key={elementkey}
        paperSize={pdfPaperSize}
      >
        <Box
          className={`${classes.floorplanContainer} ${pageHeightClassName} ${pageWidthClassName}`}
          p={3}
        >
          <div
            style={{
              backgroundImage: `url('${floorplanUrl}')`,
            }}
            className={classes.floorPlanImg}
          ></div>
        </Box>
      </SurveyPDFPage>
    );
  };

  return (
    <>
      {isVisible && !floorPlanIndex &&
        propertiesPreview.map((floorplanPage: FloorplanPage, index: number) => {
          const elementkey: string = `${property.id}${index}flpln`;
          return floorplanPage?.originalUrl ?
            <PDFPreview
              pdfUrl={floorplanPage.floorplanUrl}
              originalUrl={floorplanPage.originalUrl || ''}
              paperSize={pdfPaperSize}
              mediaId={floorplanPage.id}
              key={`${property.id}-sitePlan-${floorplanPage.order}-${index}`} />
            :
            SurveyPDFPageView(floorplanPage, elementkey);
        })}
      {floorPlanIndex && propertiesPreview.length &&
        SurveyPDFPageView(propertiesPreview[floorPlanIndex - 1], `${property.id}${floorPlanIndex}FP`)}
    </>
  );
};

export type Props = {
  property: Property;
  propertyOrder: string;
  pageMode?: string;
  pdfPaperSize: string;
  mediaState: MediaState;
  isVisible: boolean;
  floorPlanIndex?: number;
};

const mapStateToProps = (state: any, ownProps: any) => ({
  surveyState: state.survey,
  mediaState: state.media,
  ...ownProps,
});

PropertySitePlanPreview.displayName = 'PropertySitePlanPreview';
export default React.memo(connect(mapStateToProps)(PropertySitePlanPreview));
