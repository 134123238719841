import React, { useState } from 'react';
import {
  Button,
  Collapse,
  createStyles,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { Analytics } from '@cbreone/core-services';
import { DragAndDropList, DraggableListItem } from '@marketiq/marketiq-core-react';
import { SurveyContact, SurveyContactPatch, SurveyContactPost } from '../../types/survey-contacts';
import SurveyContactForm from '../SurveyContactForm';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';

const useStyles = makeStyles((theme: Theme) => createStyles({
  title: {
    marginBottom: theme.spacing(2),
  },
  centering: {
    padding: theme.spacing(5),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  surveyContactForm: {
    backgroundColor: theme.palette.grey['100'],
    padding: theme.spacing(1.5),
  },
  contactCard: {
    backgroundColor: theme.palette.grey['100'],
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(1),
  },
  contactDetail: {
    marginLeft: theme.spacing(-3),
  },
  iconSpacing: {
    padding: theme.spacing(0.5),
  },
}));

const SurveyContactList: React.FC<Props> = ({
  surveyContactList = [],
  addSurveyContact,
  updateSurveyContact,
  deleteSurveyContact,
  reorderSurveyContact,
}) => {
  const classes = useStyles();

  // new contact form expanded state
  const [isContactFormExpanded, setContactFormExpanded] = useState<boolean>(false);
  // existing contact form expanded state
  const [openContactList, setOpenContactList] = useState<string[]>([]);

  const onAddContactClicked = () => {
    setContactFormExpanded(true);
  };

  const onContactFormClose = (contactId?: string) => {
    if (contactId) {
      setOpenContactList(openContactList.filter((item) => item !== contactId));
    } else {
      setContactFormExpanded(false);
    }
  };
  // besed on the id prop, call the addContact when there is no id prop. otherwise it should be update.
  const onContactFormSubmit = (surveyContact: SurveyContactPost | SurveyContactPatch) => {
    if (surveyContact.contactId) {
      updateSurveyContact(surveyContact);
      setOpenContactList(openContactList.filter((item) => item !== surveyContact.contactId));
    } else {
      addSurveyContact(surveyContact);
      setContactFormExpanded(false);
    }
  };

  const expandSurveyContact = (contactId: string) => {
    if (!openContactList.includes(contactId)) {
      setOpenContactList([...openContactList, contactId]);
    }
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <FormLabel component="legend" className={classes.title}>
        Brokerage Team Contact Info
      </FormLabel>
      <DragAndDropList droppableId="contacts" onDragEnd={reorderSurveyContact}>
        {surveyContactList
          .sort((current, next) => { // TODO: the UI sorting could be removed once BE refactoring is done.
            const currentOrder = current.order || 0;
            const nextOrder = next.order || 0;
            return currentOrder > nextOrder ? 1 : -1;
          }).map((item, index) => (
            <div key={index} className={classes.marginBottom}>
              <Collapse
                in={!openContactList.includes(item.contactId)}
                timeout="auto"
                unmountOnExit
              >
                <DraggableListItem
                  id="SC-DragHandle"
                  onClick={() => Analytics.send({ DragHandleEntity: 'Individual Contact' })}
                  key={item.contactId}
                  index={index}
                  draggableId={item.contactId}
                  className={classes.contactCard}
                >
                  <Grid
                    container direction="row"
                    justifyContent="space-between"
                    alignItems="center" className={classes.contactDetail}>
                    <Grid
                      item xs container
                      direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography variant="subtitle1">
                          {item.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {item.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {item.email}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {item.phoneNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <IconButton
                        id="SC-EditContact"
                        className={classes.iconSpacing}
                        aria-label='edit'
                        onClick={() => expandSurveyContact(item.contactId)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        id="SC-DeleteContact"
                        className={classes.iconSpacing}
                        aria-label='delete'
                        onClick={() => deleteSurveyContact(item.contactId)}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DraggableListItem>
              </Collapse>
              <Collapse
                in={openContactList.includes(item.contactId)}
                timeout="auto"
                unmountOnExit
                className={classes.surveyContactForm}
              >
                <SurveyContactForm
                  onClose={onContactFormClose}
                  onSubmit={onContactFormSubmit}
                  surveyContact={item} />
              </Collapse>
            </div>
          ))}
      </DragAndDropList>
      <div>
        {!isContactFormExpanded && <Button
          id="SC-AddContactInfo"
          onClick={onAddContactClicked}
          variant="text"
          startIcon={<AddIcon />}
        >
          Add Contact Info
        </Button>}
      </div>
      <Collapse
        in={isContactFormExpanded}
        timeout="auto"
        unmountOnExit
        className={classes.surveyContactForm}
      >
        <SurveyContactForm onClose={onContactFormClose} onSubmit={onContactFormSubmit} />
      </Collapse>
    </FormControl>
  );
};

export type Props = {
  surveyContactList: SurveyContact[];
  addSurveyContact: UseSurveyEditorAPI['addSurveyContact'];
  deleteSurveyContact: UseSurveyEditorAPI['deleteSurveyContact'];
  updateSurveyContact: UseSurveyEditorAPI['updateSurveyContact'];
  reorderSurveyContact: UseSurveyEditorAPI['reorderSurveyAttribute'];
};

SurveyContactForm.displayName = 'SurveyContactList';
export default React.memo(SurveyContactList);
