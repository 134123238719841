import React from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { Typography } from '@marketiq/marketiq-core-react';
import { Survey, SurveyContact } from '../../types';
import SurveyContactPreview from '../SurveyContactPreview';
import { PDFPaperSize } from '../../data/constants';

export type Props = {
  surveyContactList: SurveyContact[],
  survey?: Survey;
};

const useStyles = makeStyles((theme) => createStyles({
  root: {
    paddingLeft: '20px',
  },
  header: {
    height: '50px',
    borderBottom: `1px solid ${theme.palette.info.main}`,
    marginBottom: '8px',
  },
  teamBox: {
    position: 'relative',
  },
  teamLegend: {
    transform: 'rotate(270deg)',
    position: 'absolute',
    top: '33px',
    left: '-30px',
  },
  teamName: {
    color: theme.palette.grey['800'],
  },
  teamCards: {
    width: '100%',
    marginLeft: '2rem',
  },
  portraitRoot: {
    padding: '0px 16px 20px 16px',
  },
  portraitTeamLegend: {
    padding: '16px 0px 8px 0px',
    fontSize: '12px',
  },
  portraitTeamName: {
    fontSize: '8px',
  },
  portraitHeader: {
    height: 'unset',
    border: '0px',
    marginBottom: '0px',
  },
  portraitTeamCards: {
    marginLeft: 'unset',
  },
}));

const SurveyContactPreviewList: React.FC<Props> = (
  { surveyContactList,
    survey },
) => {
  surveyContactList.sort((a, b) => (a.order || 0) - (b.order || 0));
  const isLandscapeMode = survey && survey.pdfPaperSize === PDFPaperSize.Landscape;
  const classes = useStyles();
  return (
    <Grid container direction="column" className={`${classes.root} ${!isLandscapeMode ? classes.portraitRoot : ''}`}>
      <Grid item className={`${classes.header} ${!isLandscapeMode ? classes.portraitHeader : ''}`}>
        {!isLandscapeMode && <Grid item className={classes.portraitTeamLegend}>
          <Typography variant="body4" className={`${classes.teamName} ${!isLandscapeMode ? classes.portraitTeamName : ''}`}>
            Your CBRE Team
          </Typography>
        </Grid>}
      </Grid>
      <Grid
        item container direction="row"
        className={classes.teamBox}
      >
        {isLandscapeMode && <Grid item className={classes.teamLegend}>
          <Typography variant="body4" className={classes.teamName}>
            Your CBRE Team
          </Typography>
        </Grid>}
        <Grid item className={`${classes.teamCards} ${!isLandscapeMode ? classes.portraitTeamCards : ''}`}>
          {
            surveyContactList?.map(
              (contact, index) => <SurveyContactPreview
                key={index} surveyContact={
                  contact} /* isLandscapeMode={isLandscapeMode} */ />,
            )
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

SurveyContactPreviewList.displayName = 'SurveyContactPreviewList';
export default React.memo(SurveyContactPreviewList);
