import React from 'react';
import TextField from '@marketiq/marketiq-core-react/components/DebouncedTextField';
import { DataObject } from '../../types';
import { INPUT_TYPE } from '../../data/constants';

const FormCellRenderer: React.FC<CellProps> = ({
  name,
  label,
  property,
  type,
  handlePropertyUpdate,
}) => {
  const [value, setValue] = React.useState(property ? property[name] : '');

  const handleUpdate = React.useCallback(
    (e: any) => {
      if (value !== e.target.value) {
        setValue(e.target.value);
        handlePropertyUpdate(e);
      }
    },
    [property, name, label, type],
  );
  return (
    <TextField
      value={value}
      multiline={type === INPUT_TYPE.MULTI_LINE}
      rows={type === INPUT_TYPE.MULTI_LINE ? 3 : 1}
      onChange={handleUpdate}
      fullWidth
      name={name}
      label={label}
      debounceTime={400}
      onBlur={handleUpdate}
    />
  );
};

type CellProps = {
  name: string;
  label: string;
  property: DataObject;
  type?: string;
  handlePropertyUpdate: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
};

FormCellRenderer.displayName = 'FormCellRenderer';
export default React.memo(FormCellRenderer);
