import Analytics from '@cbreone/core-services/dist/services/analytic-service';
import { DraggableAccordion, DraggableAccordionSummary } from '@marketiq/marketiq-core-react';
import DragAndDropList from '@marketiq/marketiq-core-react/components/DragAndDropList';
import InlineEditTextField from '@marketiq/marketiq-core-react/components/InlineEditTextField';
import parseInputChange from '@cbreone/utilities/dist/parseInputChange';
import {
  Accordion, AccordionSummary,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  AccordionDetails as SectionDetails,
  Select,
  Switch,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, {
  ReactElement,
  ReactText,
  useEffect,
  useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';
import marketMapAction from '../../redux/actions/marketMapAction';
import mediaFileAction from '../../redux/actions/mediaFileAction';
import setSurveySummaryAction from '../../redux/actions/surveyAction';
import PDFOrigin from '../../redux/data/constants';
import { MarketMapStatePatch } from '../../redux/types/dataTypes';
import { RouteParams } from '../../routes/routes';
import { Property, Survey } from '../../types';
import { getArrayChunks } from '../../utilities';
import CoverSectionForm from './CoverSectionForm';
import CustomSection from './CustomSection';
import CustomizeLegend from './CustomizeLegend';
import EditColumns from './EditColumns';
import MarketMapSectionForm from './MarketMapSectionForm';
import PropertiesSectionForm from './PropertiesSectionForm';
import PropertyComparisonForm from './PropertyComparisonForm';
import TourScheduleSectionForm from './TourScheduleSectionForm';

const commonSectionStyles = {
  marginTop: '0 !important',
  marginBottom: '0 !important',
  boxShadow: 'none',
  minHeight: 'initial !important',
  transitionDuration: '0ms',
  '&:before': {
    top: 0,
    height: 0,
    backgroundColor: 'transparent',
    transition: 'none',
  },
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  pdfPaperSizeSelect: {
    margin: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)}px)`,
  },
  sectionHeaders: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  sections: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  sectionRoot: commonSectionStyles,
  sectionExpanded: {
    ...commonSectionStyles,
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
  },
  sectionSummaryRoot: {
    padding: 0,
    transition: 'none',
  },
  notReorderable: {
    marginLeft: theme.spacing(4.25),
  },
  expandIcon: {
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
  },
  sectionSummaryContent: {
    margin: 0,
  },
  sectionSummaryContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flex: 1,
  },
  sectionSummaryHandle: {
    minWidth: 'initial',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  sectionSummaryPositionText: {
    marginRight: theme.spacing(2),
    display: 'inline-block',
  },
  sectionSummaryText: {
    textTransform: 'capitalize',
    display: 'inline-block',
  },
  sectionTextField: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
  sectionTextFieldBase: {
    paddingTop: '5px',
    paddingBottom: '1px',
  },
  sectionDetails: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingTop: theme.spacing(3),
  },
  hiddenSection: {
    width: 0,
    height: 0,
    overflow: 'hidden',
  },
  addSectionButton: {
    marginLeft: theme.spacing(3.5),
  },
}));

const stopAccordionPropagation = (e: React.MouseEvent | React.FocusEvent) => e.stopPropagation();

const SurveyForm: React.FC<Props> = React.forwardRef((props, ref: any) => {
  const {
    survey,
    updateSurvey,
    onReorder,
    onSurveyContactAdd,
    onSurveyContactDelete,
    onSurveyContactUpdate,
    onDeleteSurveySection,
    onUpdateSurveySection,
    onUpdatePropertyFieldRule,
    onUpdateMedia,
    onUpdatePreviewPosition,
    onOpenTourPanel,
    params,
    surveyApi,
  } = props;
  const [openSections, setOpenSections] = useState<{ [x: string]: boolean; }>({});
  const [pdfPaperSize, setPdfPaperSize] = useState<string>(survey.pdfPaperSize);
  const dispatch: Dispatch<any> = useDispatch();
  const classes = useStyles();
  // handles accordion expanded state since drag and drop causes them to rerender and lose the state internally
  const handleAccordionExpandChange = (id: string) => (
    e: React.ChangeEvent<{}>, expanded: boolean,
  ) => {
    setOpenSections({ ...openSections, [id]: expanded });
  };

  // handles form field events when updating the survey
  const handleSurveyUpdate = (e: React.ChangeEvent<any>) => {
    const { name, value } = (e.target ? parseInputChange(e.target) : e) as {
      name: string;
      value: string | number | boolean | undefined;
    };
    updateSurvey({ id: survey.id, [name]: value });
  };

  const sectionIndexMap = useMemo(() => {
    let index = 0;
    return survey.sections.reduce((acc, section) => {
      if (section.isHidden) {
        return { ...acc, [section.surveySectionId]: <>&#8211;</> };
      }
      index += 1;
      return { ...acc, [section.surveySectionId]: index };
    }, {} as { [x: string]: ReactText | ReactElement; });
  }, [survey.sections]);

  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [customizeLegendDrawerOpen, setCustomizeLegendDrawerOpen] = useState(false);

  useEffect(() => {
    let propertyChunks = [];
    if (survey.isIncludeHeader) {
      propertyChunks = getArrayChunks(survey.properties, 12);
    } else {
      propertyChunks = getArrayChunks(survey.properties, 14);
    }
    const data = {
      id: survey.id,
      mapCount: survey.isIncludeLegend ? propertyChunks.length : 1,
      properties: survey.properties,
      mapSize: [0, 0] as [number, number],
      pdfPaperSize: survey.pdfPaperSize,
      marketMapBamData: survey.marketMapBamData,
      isIncludeLegend: survey.isIncludeLegend,
      isIncludeHeader: survey.isIncludeHeader,
      marketMapStyle: survey.marketMapStyle,
      activeMapIndex: -1,
      origin: PDFOrigin.PDFPreview,
      points: [],
      resettingMap: false,
    };
    dispatch(marketMapAction.setMarketMapAction(data));
    dispatch(mediaFileAction.addMediaFilesAction({
      ids: survey.properties.map((item: Property) => item.id),
    }));
  }, [survey.id]);

  const handlePdfSizeUpdate = (e: React.ChangeEvent<any>) => {
    const { name, value } = (e.target ? parseInputChange(e.target) : e) as {
      name: string;
      value: string;
    };
    setPdfPaperSize(value);
    const data = {
      pdfPaperSize: value,
      resettingMap: true,
    } as MarketMapStatePatch;
    dispatch(setSurveySummaryAction({ pdfPaperSize: value }));
    dispatch(marketMapAction.updatePdfPaperSizeAction(data));
    updateSurvey({ id: survey.id, [name]: value });
  };

  return (
    <>
      <FormControl
        fullWidth
        variant="outlined"
        className={classes.pdfPaperSizeSelect}>
        <InputLabel id="pdf_paper_size-label">PDF Orientation</InputLabel>
        <Select
          labelId="pdf_paper_size-label"
          id="pdf_paper_size-select"
          value={pdfPaperSize}
          name="pdfPaperSize"
          onChange={handlePdfSizeUpdate}
          label="PDF Paper Size"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value="Landscape">Landscape</MenuItem>
          <MenuItem value="Portrait">Portrait</MenuItem>
        </Select>
      </FormControl>
      <div className={classes.sectionHeaders}>
        <Typography variant="overline">Section</Typography>
        <Typography variant="overline">Options</Typography>
      </div>
      <DragAndDropList
        droppableId="sections"
        onDragEnd={onReorder}
        className={classes.sections}
      >
        {survey.sections.map(({
          name, isHidden, surveySectionId, order, isDeletable, isRenamable, isReorderable,
        }) => {
          const { Section, Summary } = isReorderable
            ? { Section: DraggableAccordion, Summary: DraggableAccordionSummary }
            : { Section: Accordion, Summary: AccordionSummary };
          const expanded = openSections[surveySectionId] ? openSections[surveySectionId] : false;
          const kabobName = name.replace(' ', '-');
          let clickName = isRenamable ? 'CustomSection' : name.replace(/(^\w{1})|(\s+\w{1})/g, (l) => l.toUpperCase()).replace(' ', '');
          clickName = clickName === 'Cover' ? 'CoverPage' : clickName;
          return (
            <Section
              id="SC-ExpandCollapse"
              key={surveySectionId}
              expanded={expanded}
              onChange={handleAccordionExpandChange(surveySectionId)}
              onClick={() => {
                onUpdatePreviewPosition(`${order}`, expanded);
                Analytics.send({ ExpandCollapseEntity: clickName });
              }}
              TransitionProps={{ timeout: 0 }}
              classes={{
                root: classes.sectionRoot,
                expanded: classes.sectionExpanded,
              }}
              {...(!isReorderable ? {} : {
                index: order,
                draggableId: surveySectionId,
              })}
            >
              <Summary
                expandIcon={<ExpandMoreIcon />}
                aria-label={`Expand ${name} Section`}
                aria-controls={`${kabobName}-section-content`}
                id={`${kabobName}-section-header`}
                className={!isReorderable ? classes.notReorderable : ''}
                classes={{
                  expanded: classes.sectionExpanded,
                  content: classes.sectionSummaryContent,
                  root: classes.sectionSummaryRoot,
                  expandIcon: classes.expandIcon,
                  ...(!isReorderable ? {} : { handle: classes.sectionSummaryHandle }),
                }}>
                <div className={classes.sectionSummaryContentContainer}>
                  <div>
                    <Typography variant="subtitle1" className={classes.sectionSummaryPositionText}>{sectionIndexMap[surveySectionId]}</Typography>
                    {isRenamable
                      ? (
                        <InlineEditTextField

                          name="name"
                          InputProps={{
                            className: classes.sectionTextField,
                            classes: {
                              inputMarginDense: classes.sectionTextFieldBase,
                            },
                          }}
                          fallbackValue="Untitled Section"
                          value={name}
                          onClick={stopAccordionPropagation}
                          onFocus={stopAccordionPropagation}
                          onChange={(e) => onUpdateSurveySection({
                            surveySectionId, [e.target.name]: e.target.value,
                          })}
                        />
                      )
                      : <Typography variant="subtitle1" className={classes.sectionSummaryText}>{name}</Typography>
                    }
                  </div>
                  <div>
                    {isDeletable && (
                      <IconButton
                        onFocus={stopAccordionPropagation}
                        onClick={(e) => {
                          stopAccordionPropagation(e);
                          onDeleteSurveySection(surveySectionId);
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                    <Switch
                      id="SC-ToggleShowHide"
                      checked={!isHidden}
                      name='isHidden'
                      onFocus={stopAccordionPropagation}
                      onClick={(e) => {
                        Analytics.send({ ShowHideEntity: clickName });
                        stopAccordionPropagation(e);
                      }}
                      onChange={(e) => onUpdateSurveySection({
                        surveySectionId, [e.target.name]: !e.target.checked,
                      })}
                    />
                  </div>
                </div>
              </Summary>
              <SectionDetails
                className={classes.sectionDetails}
              >
                {(() => {
                  switch (name) {
                    case 'cover': return (
                      <CoverSectionForm
                        onSurveyUpdate={handleSurveyUpdate}
                        survey={survey}
                        onSurveyContactAdd={onSurveyContactAdd}
                        onSurveyContactDelete={onSurveyContactDelete}
                        onSurveyContactUpdate={onSurveyContactUpdate}
                        onSurveyContactOrderChange={onReorder}
                      />
                    );
                    case 'market map': return (
                      <MarketMapSectionForm
                        onSurveyUpdate={handleSurveyUpdate}
                        onUpdateMedia={onUpdateMedia}
                        survey={survey}
                        onOpenEditColumnsDrawer={() => setCustomizeLegendDrawerOpen(true)}
                      />
                    );
                    case 'property comparison':
                      return (
                        <PropertyComparisonForm
                          onSurveyUpdate={handleSurveyUpdate}
                          onOpenEditColumnsDrawer={() => setRightDrawerOpen(true)}
                          survey={survey}
                          params={params}
                          surveyApi={surveyApi}
                        />
                      );
                    case 'properties': return (
                      <PropertiesSectionForm
                        onSurveyUpdate={handleSurveyUpdate}
                        onOpenEditColumnsDrawer={() => setRightDrawerOpen(true)}
                        survey={survey}
                      />
                    );
                    case 'tour schedule':
                      return (
                        <TourScheduleSectionForm
                          onSurveyUpdate={handleSurveyUpdate}
                          onOpenTourScheduleDrawer={() => onOpenTourPanel(true)}
                          survey={survey}
                        />
                      );
                    default: return <CustomSection />;
                  }
                })()}
              </SectionDetails>
            </Section>
          );
        })}
      </DragAndDropList>
      {/* @Todo "post-pilot" work to reference for later. */}
      {/* <Button
      variant="text"
      startIcon={<AddIcon />}
      className={classes.addSectionButton}
      onClick={() => onAddSurveySection()}>
      Add Section
    </Button> */}
      <EditColumns
        onUpdatePropertyFieldRule={onUpdatePropertyFieldRule}
        survey={survey}
        rightDrawerOpen={rightDrawerOpen}
        onToggleDrawer={setRightDrawerOpen}
        onReorder={onReorder} />
      <CustomizeLegend
        onUpdatePropertyFieldRule={onUpdatePropertyFieldRule}
        survey={survey}
        rightDrawerOpen={customizeLegendDrawerOpen}
        onToggleDrawer={setCustomizeLegendDrawerOpen}
        onReorder={onReorder}
        updateSurvey={updateSurvey} />
    </>);
});

export type Props = {
  survey: Survey;
  updateSurvey: UseSurveyEditorAPI['updateSurvey'];
  onReorder: UseSurveyEditorAPI['reorderSurveyAttribute'];
  onSurveyContactAdd: UseSurveyEditorAPI['addSurveyContact'];
  onSurveyContactDelete: UseSurveyEditorAPI['deleteSurveyContact'];
  onSurveyContactUpdate: UseSurveyEditorAPI['updateSurveyContact'];
  onAddSurveySection: UseSurveyEditorAPI['addSurveySection'];
  onDeleteSurveySection: UseSurveyEditorAPI['deleteSurveySection'];
  onUpdateSurveySection: UseSurveyEditorAPI['updateSurveySection'];
  onUpdatePropertyFieldRule: UseSurveyEditorAPI['updatePropertyFieldRule'];
  onUpdateMedia: UseSurveyEditorAPI['updateMedia'];
  onUpdatePreviewPosition: (target: string, expand: boolean) => void;
  onOpenTourPanel: (open: boolean) => void;
  params: RouteParams;
  ref: any;
  surveyApi: UseSurveyEditorAPI;
};

SurveyForm.displayName = 'SurveyForm';
export default React.memo(SurveyForm);
