import Analytics from '@cbreone/core-services/dist/services/analytic-service';
import { ErrorModal } from '@marketiq/marketiq-core-react';
import InlineEditTextField from '@marketiq/marketiq-core-react/components/InlineEditTextField';
import {
  AppBar,
  Button,
  CircularProgress,
  Drawer,
  Fab,
  Theme,
  Toolbar,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ApartmentIcon from '@material-ui/icons/Apartment';
import CloseIcon from '@material-ui/icons/CloseRounded';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { match } from 'react-router';
import { Dispatch } from 'redux';
import { RouteParams } from '../../routes/routes';

import { Props as SurveyAppProps } from '../../apps/SurveyApp';
import SurveyForm from '../../components/SurveyForm';
import SurveyPDFPreview from '../../components/SurveyPDFPreview';
import useSurveyEditorAPI from './useSurveyEditorAPI';

import PublishShareModal from '../../components/PublishShareModal';
import { deliverableRootURL } from '../../config';
import marketMapAction from '../../redux/actions/marketMapAction';
import propertyAction from '../../redux/actions/propertyAction';
import { MediaState, SurveySummaryAction } from '../../redux/types/dataTypes';
import { exportMarketMap, getPdfUrl } from '../../services/media-service';
import { FileGeneration, Property, Survey } from '../../types';
import * as mapUtil from '../../utilities/map';
import DrawerWrapper from './DrawerWrapper';
import { STATE } from '../../data/constants';
import { SURVEY_ACTION } from '../../redux/types/actionTypes';

const copyHtmlToClipboard = require('copy-html-to-clipboard');

const drawerWidth = 400;
const appBarHeight = 64;
const appBarHeightMobile = 54;

const useStyles = ({ top = 0, right = 0, left = 0 } = {}) =>
  makeStyles((theme: Theme) =>
    createStyles({
      appBar: {
        background: theme.palette.grey[50],
        color: theme.palette.grey[800],
        marginTop: top,
        marginLeft: left,
        marginRight: right,
        width: `calc(100% - ${left}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
          duration: theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.sharp,
        }),
      },
      toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingLeft: theme.spacing(1),
      },
      buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '34%',
        left: '12%',
        marginTop: -12,
        marginLeft: -12,
      },
      inlineEdit: {
        width: '500px',
        fontSize: '21px',
        marginLeft: theme.spacing(1),
      },
      leftDrawerPaper: {
        position: 'fixed',
        zIndex: theme.zIndex.appBar,
        marginTop: top + appBarHeightMobile,
        marginLeft: 0,
        height: `calc(100% - ${top + appBarHeightMobile}px)`,
        background: theme.palette.background.paper,
        overflowX: 'hidden',
        transition: theme.transitions.create(['margin', 'width'], {
          duration: left
            ? theme.transitions.duration.enteringScreen
            : theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.sharp,
        }),
        [theme.breakpoints.up('sm')]: {
          zIndex: theme.zIndex.appBar - 1,
          marginTop: top + appBarHeight,
          height: `calc(100% - ${top + appBarHeight}px)`,
          width: drawerWidth,
          marginLeft: left,
          bottom: -(top + appBarHeight),
        },
      },
      leftDrawerContent: {
        flex: 1,
        overflow: 'scroll',
        overflowX: 'hidden',
      },
      leftDrawerFooter: {
        flex: 'none',
        padding: theme.spacing(2),
        paddingTop: '16px',
        paddingBottom: '16px',
        position: 'relative',
        boxShadow: theme.shadows[10],
        zIndex: 1,
      },
      content: {
        transition: theme.transitions.create(['margin'], {
          duration: theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.sharp,
        }),
        overflow: 'auto',
        marginTop: top + appBarHeight,
        padding: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          marginLeft: drawerWidth,
        },
      },
      leftDrawerToggle: {
        position: 'fixed',
        right: '10px',
        left: 'auto',
        bottom: '10px',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['bottom'], {
          duration: theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.sharp,
        }),
      },
      leftDrawerToggleOpen: {
        bottom: `calc(100% - ${top + appBarHeightMobile + 20}px)`,
      },
      drawer: {
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
          maxWidth: '1136px',
          width: '75%',
        },
      },
      tourDrawer: {
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
          width: '330px',
        },
      },
      publishShareModalSection: {
        padding: '30px 10px',
        background: 'none',
        boxShadow: 'none',
        '& $p': { marginBottom: 0 },
      },
      bottomBorder: {
        borderBottom: '1px solid #e0e0e0',
      },
      generatePdfText: {
        marginLeft: 72,
      },
    }),
  );

const SurveyBuilder: React.FC<Props> = ({
  match: { params },
  position,
  mediaState,
  surveyPDF,
}) => {
  const classes = useStyles(position)();
  const dispatch: Dispatch<any> = useDispatch();
  const surveyFormRef = useRef();
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(true);
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [tourPanelOp, setTourPanelOp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [pdfLinkCopied, setPdfLinkCopied] = useState(false);
  const [pdfIntervalId, setPdfIntervalId] = useState(null as any);

  useEffect(() => {
    setLeftDrawerOpen(isNotMobile);
  }, [isNotMobile]);

  const { id } = params as RouteParams;
  const resetMapStateCallback = (
    survey: Survey,
    resettingMap = true,
    patch = {},
  ) => {
    mapUtil.default
      .getMapPointsWithImages(survey, mediaState, true)
      .then((points: any) => {
        const mapData = {
          id: survey.id,
          points,
          properties: survey.properties.filter(
            (prop: Property) => prop.isHidden === undefined || !prop.isHidden,
          ),
          resettingMap,
        };
        dispatch(marketMapAction.updateMarketMapPointsAction(mapData, patch));
      });
  };
  const surveyApi = useSurveyEditorAPI(id as string, resetMapStateCallback);
  const {
    addSurveyContact,
    addSurveySection,
    deleteSurveyContact,
    deleteSurveySection,
    reorderSurveyAttribute,
    selectProperty,
    selectedProperty,
    survey,
    updateProperty,
    updateSurvey,
    updateSurveyContact,
    updateSurveySection,
    updatePropertyFieldRule,
    generateSurveyPDF,
    updateMedia,
  } = surveyApi;

  const emptyUser = {
    userIdList: [],
    authorIdList: [],
  };

  if (survey.user === null) {
    survey.user = emptyUser;
  }
  useEffect(() => {
    if (survey.id) {
      fetchPDFLinks(survey.id,false);
    }
  }, [survey.id]);

  if (!survey.id) {
    return null;
  }

  if (survey.origin === 'SiteIQ') {
    Analytics.send({
      event: 'userDataReady',
      user_id: localStorage.getItem('employeeId'),
      jobTitle: localStorage.getItem('position'),
      officeName: localStorage.getItem('office'),
      email: localStorage.getItem('email'),
      functUnitName: localStorage.getItem('functUnitName'),
      SiteIQUserID: survey.user.userIdList,
    });
  }

  const handleCopySurveyPDF = () => {
    if (surveyPDF) {
      const copyText = `MIQ PDF Survey: <a href="${surveyPDF.fileUrl}">${surveyPDF.fileUrl}</a>`;
      copyHtmlToClipboard(copyText, { asHtml: true });
      setTimeout(() => {
        setPdfLinkCopied(true);
        setTimeout(() => {
          setPdfLinkCopied(false);
        }, 2000);
      }, 500);
    }
  };

  const handleGenerateSurveyPDF = () => {
    if (!loading) {
      setLoading(true);
      Analytics.send({ DownloadFromLocation: 'Builder' });
      const marketMapSection = survey.sections.find(
        (section) => section.name === 'market map',
      );
      if (`${marketMapSection?.isHidden}` === 'true') {
        generateSurveyPDF()
          .catch(() => {
            setLoading(false);
            setOpenErrorModal(true);
          })
          .finally(() => {
            fetchPDFLinks(survey.id,true);
          });
      } else {
        const curMapSize = mapUtil.default.getMapEleBoxSize('marketmap', 0);
        let mStyle = mapUtil.default.getMapStyle(survey.marketMapStyle);
        if (mStyle === 'mapboxStreets') {
          mStyle = 'mapbox://styles/mapbox/streets-v10';
        }
        if (mStyle === 'mapboxGray') mStyle = 'mapbox://styles/mapbox/light-v9';
        if (mStyle === 'mapboxDark') mStyle = 'mapbox://styles/mapbox/dark-v9';

        const mapEle = document.getElementById('marketmap-0') as any;
        if (mapEle && typeof mapEle.getPoints === 'function') {
          // eslint-disable-next-line max-len
          Promise.all([
            mapUtil.default.getMapPointsOnly(survey),
            mapEle.getMapCenter(),
            mapEle.getMapZoom(),
          ]).then((data: any[]) => {
            const payload = {
              surveyId: survey.id,
              bamRequest: {
                map: {
                  mapStyle: mStyle,
                  mapCenter: data[1],
                  mapZoom: data[2],
                  mapPitch: 0,
                  mapBearing: 0,
                  mapWidth: curMapSize[0],
                  mapHeight: curMapSize[1],
                  fileType: 'png',
                },
                points: data && data[0] ? data[0].features : [],
              },
            };
            exportMarketMap(payload)
              .then(() => {
                generateSurveyPDF()
                  .catch(() => {
                    setOpenErrorModal(true);
                    setLoading(false);
                  })
                  .finally(() => {
                    fetchPDFLinks(survey.id,true);
                  });
              })
              .catch((err) => {
                if (err.response === undefined) {
                  setOpenErrorModal(true);
                  setLoading(false);
                }
              });
          });
        }
      }
    }
  };

  const dispatchSurveyPDF = (surveyPDF: FileGeneration | null) => {
    const surveyData = { surveyPDF };
    const action: SurveySummaryAction = {
      type: SURVEY_ACTION.UPDATE_SURVEY,
      survey: surveyData as unknown as Survey,
    };
    dispatch(action);
  };

  const fetchPDFLinks = (surveyId: string, showPopup:boolean) => {
    clearInterval(pdfIntervalId);

    const fetchLinks = () => {
      getPdfUrl(surveyId)
        .then((data: FileGeneration) => {
          setLoading(false);
          if (data) {
            if (
              [STATE.COMPLETE, STATE.ERROR, STATE.FAIL].includes(data.state)
            ) {
              clearInterval(intervalId);
              if (showPopup && data.state !== STATE.COMPLETE) {
                setOpenErrorModal(true);
                dispatchSurveyPDF(null);
                return;
              }
            }
            dispatchSurveyPDF(data);
          } else {
            clearInterval(intervalId);
          }
        })
        .catch((error) => {
          setLoading(false);
          setOpenErrorModal(true);
          clearInterval(intervalId);
        });
    };
    const intervalId = setInterval(fetchLinks, 10000); // Set interval
    setPdfIntervalId(intervalId);
    fetchLinks(); // Call immediately
  };

  const handleCopyLinkSurveyPDF = () => {
    const url = `${deliverableRootURL}/${survey.id}/login`;
    const copyText = `MIQ Interactive Survey: <a href="${url}">${url}</a>\nPasscode for secure access: ${survey.passcode} `;
    copyHtmlToClipboard(copyText, { asHtml: true });
    // set to true
    setTimeout(() => {
      setLinkCopied(true);
      setTimeout(() => {
        setLinkCopied(false);
      }, 2000);
    }, 500);
  };

  const handleSurveyNameChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    updateSurvey({ id: survey.id, [name]: value });
  };

  const clickFileUpload = () => {
    // Close the Drawer first since the drawer is impacting the flatfile dropdown.
    setRightDrawerOpen(false);
  };

  const updatePreviewPosition = (target: string, expanded: boolean) => {
    if (!expanded && position !== null) {
      window.location.href = `${encodeURI(window.location.origin)}${encodeURI(
        window.location.pathname,
      )}#${target}`;
    }
  };

  const openTourPanel = () => {
    setTourPanelOp(true);
    setRightDrawerOpen(true);
  };

  return (
    <div className="survey-view">
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div>
            <InlineEditTextField
              InputProps={{
                className: classes.inlineEdit,
              }}
              type="text"
              name="name"
              disabled={true}
              onChange={handleSurveyNameChange}
              value={survey.name}
              fallbackValue="Untitled Survey"
            />
          </div>
          <Button
            id="SC-OpenManageProperties"
            data-testid="OpenProperties"
            variant="contained"
            onClick={() => {
              setTourPanelOp(false);
              setRightDrawerOpen(true);
              dispatch(propertyAction.setDrawerOpenAction(true));
            }}
            startIcon={<ApartmentIcon />}>
            {`PROPERTIES (${survey.properties.length})`}
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={isNotMobile ? 'left' : 'bottom'}
        open={isNotMobile ? true : leftDrawerOpen}
        variant="persistent"
        classes={{
          paper: classes.leftDrawerPaper,
        }}>
        <div className={classes.leftDrawerContent}>
          <SurveyForm
            ref={surveyFormRef}
            survey={survey}
            updateSurvey={(e) => {
              updateSurvey(e);
            }}
            onReorder={reorderSurveyAttribute}
            onSurveyContactAdd={addSurveyContact}
            onSurveyContactDelete={deleteSurveyContact}
            onSurveyContactUpdate={updateSurveyContact}
            onUpdateSurveySection={(e) => {
              updateSurveySection(e);
            }}
            onAddSurveySection={addSurveySection}
            onDeleteSurveySection={deleteSurveySection}
            onUpdatePropertyFieldRule={updatePropertyFieldRule}
            onUpdateMedia={updateMedia}
            onUpdatePreviewPosition={updatePreviewPosition}
            onOpenTourPanel={openTourPanel}
            params={params}
            surveyApi={surveyApi}
          />
        </div>
        <div className={classes.leftDrawerFooter}>
          <PublishShareModal
            survey={survey}
            title={`Share ${survey.name}`}
            id="SC-ShareSurvey"
            testId="share-survey"
            shareButtonDisabled={loading}>
            <div
              className={`${classes.publishShareModalSection} ${classes.bottomBorder}`}>
              {!loading && !surveyPDF && (
                <>
                  <Button
                    id="SC-DownloadSurvey"
                    data-testid="download-survey-pdf"
                    disabled={loading}
                    variant="contained"
                    fullWidth
                    onClick={handleGenerateSurveyPDF}>
                    {'Generate PDF link'}
                  </Button>
                  <p>Generate PDF to share with clients</p>
                </>
              )}
              {!loading && surveyPDF && surveyPDF.state === STATE.COMPLETE && (
                <>
                  <Button
                    id="SC-DownloadSurvey"
                    data-testid="download-survey-pdf"
                    disabled={pdfLinkCopied}
                    variant="contained"
                    fullWidth
                    onClick={handleCopySurveyPDF}>
                    {pdfLinkCopied ? 'Link Copied' : 'Copy PDF Link'}
                  </Button>
                  <p>
                    Copy your pdf link to share it directly with the client. Any
                    survey updates will require a new link to be generated.
                  </p>
                </>
              )}
              {(loading ||
                (surveyPDF && surveyPDF.state !== STATE.COMPLETE)) && (
                <>
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                  <p className={classes.generatePdfText}>
                    Your PDF is generating. Once ready, you can copy the link
                    and share it directly with the client
                  </p>
                </>
              )}
            </div>
            <div className={classes.publishShareModalSection}>
              <Button
                id="SC-InteractiveSurvey"
                data-testid="copylink-survey-pdf"
                disabled={linkCopied}
                variant="contained"
                fullWidth
                onClick={() => {
                  handleCopyLinkSurveyPDF();
                }}>
                {linkCopied ? 'Copied' : 'Copy Link'}
              </Button>
              <p>
                Copy link for interactive password-protected website. The
                interactive website is always up to date
              </p>
            </div>
          </PublishShareModal>
        </div>
      </Drawer>
      <main className={classes.content} style={{ height: 'auto' }}>
        <SurveyPDFPreview
          survey={survey}
          updateSurvey={updateSurvey}
          onPropertySelect={(e) => {
            setTourPanelOp(false);
            setRightDrawerOpen(true);
            selectProperty(e);
          }}
          onUpdateProperty={updateProperty}
          updatePropertyCoordinates={updateProperty}
        />
      </main>
      {!isNotMobile && (
        <Fab
          color="default"
          aria-label="toggle"
          className={`${classes.leftDrawerToggle} ${
            leftDrawerOpen && classes.leftDrawerToggleOpen
          }`}
          onClick={() => setLeftDrawerOpen(!leftDrawerOpen)}>
          {leftDrawerOpen ? <CloseIcon /> : <EditIcon />}
        </Fab>
      )}
      <DrawerWrapper
        variant="temporary"
        anchor="right"
        open={rightDrawerOpen}
        hideBackdrop={!selectedProperty && tourPanelOp}
        onClose={() => {
          if (selectedProperty) {
            selectProperty();
          }
          setRightDrawerOpen(false);
          dispatch(propertyAction.setDrawerOpenAction(false));
        }}
        classes={{
          paperAnchorRight: tourPanelOp ? classes.tourDrawer : classes.drawer,
        }}
        onClickFileupload={clickFileUpload}
        showManagePropertyView={!selectedProperty && !tourPanelOp}
        showTourPanel={!selectedProperty && tourPanelOp}
        onDismiss={() => {
          setRightDrawerOpen(false);
          dispatch(propertyAction.setDrawerOpenAction(false));
        }}
        params={params}
        surveyApi={surveyApi}
      />
      {openErrorModal && (
        <ErrorModal handleErrorModalClose={() => setOpenErrorModal(false)} />
      )}
    </div>
  );
};

export type Props = {
  match: match;
  position: SurveyAppProps['position'];
  mediaState?: MediaState;
  surveyPDF?: FileGeneration;
};

const mapStateToProps = (state: any, ownProps: any) => ({
  mediaState: state.media,
  surveyPDF: state.survey.surveyPDF,
  ...ownProps,
});

SurveyBuilder.displayName = 'SurveyBuilder';
export default React.memo(connect(mapStateToProps)(SurveyBuilder));
