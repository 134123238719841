import React from 'react';
import { Theme, createStyles, makeStyles, Typography, Link, FormGroup } from '@material-ui/core';
import TextField from '@marketiq/marketiq-core-react/components/DebouncedTextField';
import { Survey } from '../../types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  tourType: {
    marginBottom: theme.spacing(2),
  },
  link: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  sectionClass: {
    width: '100%',
  },
}));
const TourScheduleSectionForm: React.FC<Props> = ({
  survey,
  onSurveyUpdate,
  onOpenTourScheduleDrawer,
}) => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  return (
    <section className={(classes.sectionClass)}>
      <Typography
        variant="caption"
        align="center"
        className={(classes.tourType)}
        component="p"
      >
        You can <Link
          data-testid="comparison-drawer-link"
          onClick={ () => onOpenTourScheduleDrawer()}
          className={classes.link}>add tour details here
          </Link>
      </Typography>
      <FormGroup>
        <TextField
            className={classes.tourType}
            id="SC-SurveySubtitle"
            name="tourDetails"
            onBlur={onSurveyUpdate}
            placeholder={`January 1, ${currentYear}`}
            value={survey?.tourDetails}
            label="Date"
            fullWidth
            debounceTime={0}
        />
      </FormGroup>
    </section>
  );
};

export type Props = {
  onSurveyUpdate: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {
    name?: string | undefined;
    value: unknown;
  }>, checkedOrChild?: boolean | React.ReactNode) => void;
  survey: Survey;
  onOpenTourScheduleDrawer: () => void;
};

TourScheduleSectionForm.displayName = 'TourScheduleSectionForm';
export default TourScheduleSectionForm;
