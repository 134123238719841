import {
  Grid,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  imgPage: {
    overflow: 'hidden',
    backgroundColore: theme.palette.background.paper,
    width: '100%',
  },
  imgBox: {
    // The css-style 'pageContainerLandscapeHeaderHeight' in parent container(PropertyImagePreview) is already defined as height: '7in'.
    // So, it's child(e.g: imageBox) also should calculate the height based on the exact same value as in parent, i.e. should be '7in'.
    height: 'calc(7in / 2) !important',
  },
  imgInnerBox: {
    height: '100% !important',
    width: '100% !important',
  },
  imageStyle: {
    margin: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

const PropertyImages: React.FC<Props> = ({
  imgList,
}) => {
  const classes = useStyles();
  return (
  <div className={(classes.imgPage)}>
    <Grid container spacing={2}>
      {
        imgList?.map((imgLink, index) => (
        <Grid
          xs={6} spacing={2}
          container item
          key={index} alignItems='center' className={(classes.imgBox)}>
          <Grid container item className={(classes.imgInnerBox)} >
            <img className={(classes.imageStyle)} src={`${imgLink}?d=print`} alt="Images of Properties"/>
          </Grid>
        </Grid>
        ))}
    </Grid>
  </div>
  );
};

export type Props = {
  imgList: string[];
};

PropertyImages.displayName = 'PropertyImages';
export default PropertyImages;
